/*default version*/
@font-face {
  font-family: 'Atkinson Hyperlegible';
  src: local('Atkinson Hyperlegible'),
    url('./fonts/atkinsonhyperlegible-regular-webfont.woff2') format('woff2'),
    url('./fonts/atkinsonhyperlegible-regular-webfont.woff') format('woff');
}
/*bold version*/
@font-face {
  font-family: 'Atkinson Hyperlegible';
  src: local('Atkinson Hyperlegible'),
    url('./fonts/atkinsonhyperlegible-bold-webfont.woff2') format('woff2'),
    url('./fonts/atkinsonhyperlegible-bold-webfont.woff') format('woff');
  font-weight: bold;
}
/*italic version*/
@font-face {
  font-family: 'Atkinson Hyperlegible';
  src: local('Atkinson Hyperlegible'),
    url('./fonts/atkinsonhyperlegible-italic-webfont.woff2') format('woff2'),
    url('./fonts/atkinsonhyperlegible-italic-webfont.woff') format('woff');
  font-style: italic;
}
/*bold italic version*/
@font-face {
  font-family: 'Atkinson Hyperlegible';
  src: local('Atkinson Hyperlegible'),
    url('./fonts/atkinsonhyperlegible-bolditalic-webfont.woff2') format('woff2'),
    url('./fonts/atkinsonhyperlegible-bolditalic-webfont.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

body {
  margin: 0;
  font-family: 'Atkinson Hyperlegible', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: rgb(52, 52, 51);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #ffbf3d;
  text-decoration: none;
}
a:hover {
  color: #ffbf3d;
  text-decoration: underline;
}
