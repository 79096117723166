.at {
  color: #ff7f00;
  text-decoration: none;
  font-weight: 500;
}

.carousel-item {
  text-align: center;
  padding-bottom: 65px;
}

.carousel-item a img {
  border-left: 10px solid white;
  border-right: 10px solid white;
  border-top: 10px solid white;
  border-bottom: 10px solid white;
  padding-bottom: 0px;
  border-radius: 5px;
}

.carousel-indicators {
  margin-bottom: .5rem;
}

.carousel-indicators button{
  width: 10px !important;
  height: 10px !important;
  opacity: .4;
  border-radius: 100%;
  margin: 0px 4px;
  cursor: pointer; 
  background-color: #ffbf3d !important;
}

.carousel-caption span {
  opacity: 1;
  background-color: #0000005c;
}

.carousel-control-next-icon {
  filter: brightness(0) saturate(100%) invert(77%) sepia(31%) saturate(984%) hue-rotate(346deg) brightness(100%) contrast(102%);
}

.carousel-control-prev-icon {
  filter: brightness(0) saturate(100%) invert(77%) sepia(31%) saturate(984%) hue-rotate(346deg) brightness(100%) contrast(102%);
}

.portrait {
  border-radius: 5px;
}